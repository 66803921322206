@use "variables";

@mixin aspect-radio($width, $height) {
  position: relative;
  overflow: hidden;
}

@mixin tablet {
  @media (min-width: #{variables.$small-breakpoint}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{variables.$main-breakpoint}) {
    @content;
  }
}

