.answer-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  margin-left: 1.5rem;
  li {
    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }
}
