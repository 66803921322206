@use '../../../assets/styles/variables';
@use '../../../assets/styles/mixins';

.conclusion-block {
  display: flex;
  text-align: center;
  &__container {
    background: variables.$backgroundBlue;
    padding: 2.3rem 0.5rem;
    max-width: 85rem;
    @include mixins.desktop {
      padding: 2.3rem 8rem;
    }
  }
  &__title {
    font-size: 2rem;
    font-weight: bold;
    color: variables.$darkBlue;
    margin-bottom: 2.3rem
  }

  &__description {
    margin-bottom: 2rem;
  }
}
