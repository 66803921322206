@use '../../../assets/styles/variables';
@use '../../../assets/styles/mixins';

.dropdown {
  $this: &;
  position: relative;
  color: variables.$white;

  &__button {
    padding: 0;
    font-weight: bold;
    border: none;

    &:hover {
      border-bottom: 3px solid;
    }

    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$white transparent transparent transparent;
      margin-left: 7px;
    }
  }

  &__content {
    display: none;
    position: absolute;
    left: 60%;
    padding: 15px;
    background-color: variables.$white;
    z-index: 100;

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 0 14px;
      border-color: transparent transparent transparent variables.$white;
    }
  }

  &--active {
    #{$this}__button {
      border-bottom: 3px solid;
    }
    #{$this}__content {
      display: block;
      top: 25px;
      text-align: center;
      margin-top: 8px;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
      color: variables.$black;
    }
  }
}
