// MOBILE
$mobile-side-margin: 3%;

// GRID
$container-width: 1158px;

// MEDIA QUERIES
$tiny-breakpoint: 426px;
$small-breakpoint: 768px;
$main-breakpoint: 980px;

// HEADER
$header-height: 6rem;
$footer-height: 7.5rem;
$nextStepBlock-height: 6rem;

// COLORS
$darkBlue: #23477E;
$lightBlue: #6CA6DB;
$backgroundBlue: #ECF3FA;
$backgroundGray: #F5F8F9;
$gray: #444444;
$lightGray: #D6D6D6;
$white: #FFFFFF;
$blue: #3479f6;
$mike-blue: #004277;
$green: #005734;
$black: #000000;

$primary-color: $blue;
$secondary-color: $mike-blue;
