@use '../../../assets/styles/variables';
@use '../../../assets/styles/mixins';

.modal-overlay {
  position: fixed;
  z-index: 99;
  top: 0;
  height: 100vh;
  width: 100vw;
  padding-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(.5rem);
  @supports not (backdrop-filter: blur(.5rem)) {
    background-color: rgba(255, 255, 255, .9);
  }
}

.modal {
  margin: 0 1.5rem;
  padding: 1rem;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow-y: hidden;
  border-radius: 4px;
  outline: none;
  &__title {
    text-align: center;
    color: variables.$darkBlue;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 0;
  }
  &__content {
    text-align: center;
  }
}


