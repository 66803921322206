.skeleton {
  color: transparent;
  cursor: progress;
  user-select: none;
  width: 100%;
  height: 100%;
  background: #d8d8d8;

  animation-direction: alternate;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-name: skeleton--pulse;

  &:hover {
    cursor: progress;
  }

  &--circle {
    border-radius: 9999px;
  }

}

@keyframes skeleton--pulse {
  0% {
    opacity: .85;
  }

  100% {
    opacity: .55;
  }
}
