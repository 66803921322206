@use "../../../assets/styles/variables";
@use "../../../assets/styles/mixins";

.part-block {
  $this: &;
  background-color: variables.$backgroundBlue;
  padding: 1rem;
  margin-bottom: 1.6rem;
  text-align: left;

  @include mixins.desktop {
    display: flex;
    max-width: 85rem;
    align-items: center;
    padding: 2rem;
  }

  &--with-example {
    #{$this}__content {
      @include mixins.desktop {
        flex: 0.3 1;
        padding-right: 30px;
        text-align: left;
      }
    }
  }
  &__content {
    text-align: center;
  }

  &__title {
    margin: 0 0 1rem 0;
    font-weight: bold;
    font-size: 4rem;
    color: variables.$darkBlue;
    text-transform: uppercase;
  }

  &__description {
    margin-bottom: 1.5rem;
    @include mixins.desktop {
      text-align: left;
    }
  }
}
