@use "../../../../../assets/styles/variables";

.answer-item {
  $this: &;
  display: flex;
  align-items: center;
  &--checked {
    #{$this}__key {
      color: variables.$white;
      background: variables.$lightBlue;
    }
  }
  &__key {
    align-self: flex-start;
    border: 1px solid variables.$lightBlue;
    padding: 2px 8px;
    border-radius: 50%;
    font-weight: bold;
    cursor: pointer;
    margin-right: 0.7rem;
    &:focus {
      outline: 0;
    }
  }
}
