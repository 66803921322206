@use '../../../../assets/styles/variables';
@use '../../../../assets/styles/mixins';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  &__top {
    display: flex;
    height: 2.2rem;
    background-color: variables.$green;
  }
  &__language-switch {
    display: flex;
    margin-left: 15px;
  }

  &__bottom {
    height: 3.9rem;
    background-color: variables.$white;
    display: flex;
    box-shadow: 0 0 86px #00427722;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem;

    @include mixins.desktop {
      padding: 0 6rem;
    }

    img {
      align-self: center;
      height: 40px;
    }
  }
}
