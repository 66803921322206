@use '../../../assets/styles/variables';
@use '../../../assets/styles/mixins';

.button {
  color: variables.$white;
  border-radius: 60px;
  border: none;
  outline: none;
  width: calc(100% - 1rem);
  font-size: 0.8rem;

  &:hover {
    cursor: pointer;
  }

  @include mixins.desktop {
    width: 15rem;
  }

  &--primary {
    background-color: variables.$darkBlue;
  }

  &--white {
    background-color: white;
    border: 1px solid variables.$darkBlue;
    color: variables.$darkBlue;
  }

  &--small {
    height: 1rem;
  }

  &--medium {
    height: 1.5rem;
  }

  &--large {
    min-height: 2.2rem;
  }

  &--bold {
    font-weight: bold;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--loading {
    background-color: variables.$lightBlue;
    color: variables.$gray;
  }

  &--disabled {
    background-color: variables.$lightGray;
    color: variables.$white;
    &:hover {
      cursor: default;
    }
  }
}
