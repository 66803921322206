@use '../../../../assets/styles/variables';
@use '../../../../assets/styles/mixins';

.footer {
  width: 100%;
  z-index: 98;
  height: variables.$footer-height;
  background-color: variables.$green;
  color: variables.$white;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 100%;
    text-align: center;

    li {
      flex: 0.2;
      margin-bottom: 0.6rem;

      button {
        border: none;
        font-weight: bold;
        font-size: 0.7rem;
      }
    }

    @include mixins.desktop {
      flex-direction: row;

      ul {
        flex-direction: row;
      }

      li + li {
        border-left: 1px solid variables.$white;
      }
    }
  }

  &__copyright {
    text-transform: uppercase;
  }
}
